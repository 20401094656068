export const clearSpouseFields = (functionName) => {
    functionName('CpfConjuge', '')
    functionName('NomeConjuge', '')
    functionName('EmailConjuge', '')
    functionName('TelefoneConjuge', '')
    functionName('DataDeNascimentoConjuge', '')
    functionName('NomeDaMaeConjuge', '')
    functionName('RgConjuge', '')
    functionName('SexoConjuge', '')
    functionName('NaturalidadeConjuge', '')
    functionName('CepConjuge', '')
    functionName('EnderecoConjuge', '')
    functionName('BairroConjuge', '')
    functionName('EstadoConjuge', '')
    functionName('CidadeConjuge', '')
    functionName('ComplementoConjuge', '')
    functionName('ProfissaoConjuge', '')
    functionName('RendaConjuge', '')
  }

  export const clearPartnersFields = (functionName, i) =>{
    functionName(`socios.${i}.CpfConjuge`, '')
    functionName(`socios.${i}.NomeConjuge`, '')
    functionName(`socios.${i}.EmailConjuge`, '')
    functionName(`socios.${i}.TelefoneConjuge`, '')
    functionName(`socios.${i}.DataDeNascimentoConjuge`, '')
    functionName(`socios.${i}.NomeDaMaeConjuge`, '')
    functionName(`socios.${i}.RgConjuge`, '')
    functionName(`socios.${i}.SexoConjuge`, '')
    functionName(`socios.${i}.NaturalidadeConjuge`, '')
    functionName(`socios.${i}.CepConjuge`, '')
    functionName(`socios.${i}.EnderecoConjuge`, '')
    functionName(`socios.${i}.BairroConjuge`, '')
    functionName(`socios.${i}.EstadoConjuge`, '')
    functionName(`socios.${i}.CidadeConjuge`, '')
    functionName(`socios.${i}.ComplementoConjuge`, '')
    functionName(`socios.${i}.ProfissaoConjuge`, '')
    functionName(`socios.${i}.RendaConjuge`, '')
  }