/* eslint-disable */
import { useEffect } from 'react'
import { maskCep, maskCnpj, maskCurrency, maskPhoneNumber } from '../utils/masks'
import { agency } from '../../shared/agency'

const PjForm = ({ register, watch, setValue, errors }) => {
    const cnpj = watch('Cnpj')
    useEffect(() => {
        setValue('Cnpj', maskCnpj(cnpj))
    }, [cnpj])

    const telefoneDaEmpresa = watch('TelefoneDaEmpresa')
    useEffect(() => {
        setValue('TelefoneDaEmpresa', maskPhoneNumber(telefoneDaEmpresa))
    }, [telefoneDaEmpresa])

    const cepDaEmpresa = watch('CepDaEmpresa')
    useEffect(() => {
        setValue('CepDaEmpresa', maskCep(cepDaEmpresa))
    }, [cepDaEmpresa])

    const faturamentoAnual = watch('FaturamentoAnual')
    useEffect(() => {
        setValue('FaturamentoAnual', maskCurrency(faturamentoAnual))
    }, [faturamentoAnual])

    return (
        <div className="container pjForm grid">

            {/* Row 1 */}

            <div
                className='box razaoSocialPj'
            >
                <input
                    type="text"
                    placeholder='Razão Social'
                    {...register('RazaoSocial', { required: true })}
                    onChange={(e) => setValue('RazaoSocial', e.target.value)}
                />
                {errors.RazaoSocial && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
                className='box cnpjPj'
            >
                <input
                    type="text"
                    placeholder='CNPJ'
                    {...register('Cnpj', { required: true })}
                    onChange={(e) => setValue('Cnpj', e.target.value)}
                />
                {errors.Cnpj && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
                className='box telefonePj'
            >
                <input
                    type="text"
                    placeholder='Telefone'
                    {...register('TelefoneDaEmpresa', { required: true })}
                    onChange={(e) => setValue('TelefoneDaEmpresa', e.target.value)}
                />
                {errors.TelefoneDaEmpresa && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            {/* Row 2 */}

            <div
                className='box cepPj'
            >
                <input
                    type="text"
                    placeholder='CEP'
                    {...register('CepDaEmpresa', { required: true })}
                    onChange={(e) => setValue('CepDaEmpresa', e.target.value)}
                />
                {errors.CepDaEmpresa && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
                className='box enderecoPj'
            >
                <input
                    type="text"
                    placeholder='Endereço'
                    {...register('EnderecoDaEmpresa', { required: true })}
                    onChange={(e) => setValue('EnderecoDaEmpresa', e.target.value)}
                />
                {errors.EnderecoDaEmpresa && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
                className='box bairroPj'
            >
                <input
                    type="text"
                    placeholder='Bairro'
                    {...register('BairroDaEmpresa', { required: true })}
                    onChange={(e) => setValue('BairroDaEmpresa', e.target.value)}
                />
                {errors.BairroDaEmpresa && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
                className='box estadoPj'
            >
                <select
                    {...register('EstadoDaEmpresa', { required: true })}
                    onChange={(e) => setValue('EstadoDaEmpresa', e.target.value)}
                >
                    <option value="" disabled selected>Estado</option>
                    <option value="RS">Rio Grande do Sul</option>
                </select>
                {errors.EstadoDaEmpresa && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            {/* Row 3 */}

            <div
                className='box cidadePj'
            >
                <select
                    {...register('CidadeDaEmpresa', { required: true })}
                    onChange={(e) => setValue('CidadeDaEmpresa', e.target.value)}
                >
                    <option value="" disabled selected>Cidade</option>
                    <option value="panambi">Panambi</option>
                </select>
                {errors.CidadeDaEmpresa && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
                className='box emailPj'
            >
                <input
                    type="text"
                    placeholder='E-mail'
                    {...register('EmailDaEmpresa', { required: true })}
                    onChange={(e) => setValue('EmailDaEmpresa', e.target.value)}
                />
                {errors.EmailDaEmpresa && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
                className='box faturamentoPj'
            >
                <input
                    type="text"
                    placeholder='Faturamento dos últimos 12 meses'
                    {...register('FaturamentoAnual', { required: true })}
                    onChange={(e) => setValue('FaturamentoAnual', e.target.value)}
                />
                {errors.FaturamentoAnual && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div className='portePj bgColorElement'>
                <label>
                    <span>Porte da empresa</span>
                </label>
                <div>
                    <label>
                        ME
                        <input
                            className='boxME'
                            id='me'
                            type="radio"
                            name='porteDaEmpresa'
                            value='ME'
                            defaultChecked={true}
                            {...register('PorteDaEmpresa')}
                            onChange={(e) => setValue('PorteDaEmpresa', e.target.value)}
                        />
                    </label>
                    <label>EPP
                        <input
                            className='boxEPP'
                            id='epp'
                            type="radio"
                            name='porteDaEmpresa'
                            value='EPP'
                            {...register('PorteDaEmpresa')}
                            onChange={(e) => setValue('PorteDaEmpresa', e.target.value)}
                        />
                    </label>
                </div>
            </div>

            {/* Row 4*/}

            <div className='atividadePj bgColorElement'>
                <label>
                    <span>Atividade</span>
                </label>
                <div>
                    <label>
                        Indústria
                        <input
                            className='boxIndustria'
                            id='industria'
                            type="radio"
                            name='atividadeDaEmpresa'
                            value='1'
                            defaultChecked={true}
                            {...register('AtividadeDaEmpresa')}
                            onChange={(e) => setValue('AtividadeDaEmpresa', e.target.value)}
                        />
                    </label>
                    <label>Comércio
                        <input
                            className='boxComercio'
                            id='comercio'
                            type="radio"
                            name='atividadeDaEmpresa'
                            value='2'
                            {...register('AtividadeDaEmpresa')}
                            onChange={(e) => setValue('AtividadeDaEmpresa', e.target.value)}
                        />
                    </label>
                    <label>Serviço
                        <input
                            className='boxServico'
                            id='servico'
                            type="radio"
                            name='atividadeDaEmpresa'
                            value='3'
                            {...register('AtividadeDaEmpresa')}
                            onChange={(e) => setValue('AtividadeDaEmpresa', e.target.value)}
                        />
                    </label>
                </div>
            </div>

            <div className='associadoPj bgColorElement'>
                <label>
                    <span>Associado ao Sicoob</span>
                </label>
                <div>
                    <label>
                        Sim
                        <input
                            className='boxIndustria'
                            id='industria'
                            type="radio"
                            name='associadoAoSicoob'
                            value='1'
                            defaultChecked={true}
                            {...register('AssociadoAoSicoob')}
                            onChange={(e) => setValue('AssociadoAoSicoob', e.target.value)}
                        />
                    </label>
                    <label>Não
                        <input
                            className='boxComercio'
                            id='comercio'
                            type="radio"
                            name='associadoAoSicoob'
                            value='2'
                            {...register('AssociadoAoSicoob')}
                            onChange={(e) => setValue('AssociadoAoSicoob', e.target.value)}
                        />
                    </label>

                </div>
            </div>

            <div
                className='box agenciaPj'
            >
                <select
                    {...register('Agencia', { required: true })}
                    onChange={(e) => setValue('Agencia', e.target.value)}
                >
                    <option
                        value="" disabled selected
                    >
                        Agência
                    </option>
                    <option
                        value={agency.find(a => a.id !== null || a.id !== undefined).id}
                    >
                        {agency.find(a => a.name !== "").name}
                    </option>
                </select>
                {errors.Agencia && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>
        </div>
    )
}

export default PjForm