import React from 'react'

const BxSectionF = () => {
    return (
        <section id="sectionF">
            <div className="container">
                <h2
                    style={
                        {
                            color: '#174724',
                            fontStyle: 'italic',
                            fontWeight: 'normal',
                            fontSize: '5rem',
                            textAlign: 'center',
                            marginBottom: '50px'

                        }
                    }
                >
                    Documentação
                    <br />
                    <span style={
                        {
                            display: 'block',
                            color: '#089492'
                        }
                    }>necessária</span>
                </h2>

                <ul>
                    <li>RG</li>
                    <li>CPF</li>
                    <li>Comprovante de endereço</li>
                    <li>Renda dos sócios
                        <br />
                        <p>Pessoas casadas ou com união estável devem apresentar também documentação do cônjugue. Cópias devem ser coloridas.</p>
                    </li>
                    <br />
                    <li>Negativas de tributos municipais
                        <a id="negativasAtributos" href="https://panambi.atende.net/autoatendimento/servicos/certidao-negativa-de-debitos" target='_blank noreferrer'>Clique aqui</a>
                    </li>
                    <br />
                    <li style={{ listStyleType: 'none', fontWeight: 'bold', marginBottom: '0px', textTransform: 'uppercase' }}>
                        <span>Mais documentos abaixo, conforme tipo de empresa</span>
                    </li>
                    <br />
                    <li>Se empresa enquadra como ME / EPP
                        <br />
                        <span
                            style={
                                {
                                    color: '#4d4d4d',
                                    fontWeight: 'normal'
                                }
                            }
                        >- Declaração de firma individual, contrato social</span>
                        <span
                            style={
                                {
                                    color: '#4d4d4d',
                                    fontWeight: 'normal'
                                }
                            }
                        >- Empresas do Simples Nacional: PGDAS-D - Programa Gerador do Documento de Arrecadação do Simples Nacional</span>
                        <span
                            style={
                                {
                                    color: '#4d4d4d',
                                    fontWeight: 'normal'
                                }
                            }
                        >- Atualizado, com faturamento dos últimos 12 meses Empresa do Lucro Presumido: SPED Contábil | Recibo Balanço e DRE</span>
                    </li>
                </ul>
                <br />
                <p>* A documentação completa será solicitada somente após análise das informações fornecidas no formulário.
                    Preencha com seus dados e aguarde contato da RSGaranti.
                    Se você não é associado (a) da Sicoob, será necessária a abertura de uma conta corrente.</p>

            </div>
        </section>
    )
}

export default BxSectionF