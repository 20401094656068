/* eslint-disable */
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import PjForm from './formGroup/PjForm'
import UploadForm from './formGroup/UploadForm'
import PfForm from './formGroup/PfForm'
import SocioForm from './formGroup/SocioForm'
import CreditoForm from './formGroup/CreditoForm'
import TermosForm from './formGroup/TermosForm'
import axios from 'axios'
import sendEmail from '../services/send-email'
import { currencyToNumber } from './utils/masks'
// import { DevTool } from '@hookform/devtools'

const Form = () => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        reset,
        formState: { errors }
    } = useForm()

    // Loading, submited and message
    const [loading, setLoading] = useState(false)

    const [message, setMessage] = useState(null)
    const [submited, setSubmited] = useState(false)

    // Configs
    const [spanRef, setSpanRef] = useState(null)
    const getSelectedFileRef = value => {
        setSpanRef(value)
    }

    const [initialValue] = useState("Nenhum arquivo selecionado")
    const megabytes = watch('Megabytes')

    // Resets
    // const setRadioAndCheckboxDefaultValues = () => {
    //     setValue('EstadoCivil', '1')
    //     setValue('PorteDaEmpresa', 'ME')
    //     setValue('AtividadeDaEmpresa', '1')
    //     setValue('AssociadoAoSicoob', '1')
    //     setValue('FinalidadeDoCredito', '0')
    //     setValue('Sexo', '')
    //     setValue('Autorizo1', false)
    //     setValue('Autorizo2', false)
    //     setValue("Autorizo3", false)
    //     setValue("Autorizo4", false)
    // }

    // const currentSocios = watch('socios', []);
    // const resetPartnerFields = () => {
    //     if (currentSocios)
    //         for (let i = 0; i < currentSocios?.length || 0; i++)
    //             for (let key in currentSocios[i])
    //                 setValue(`socios[${i}].${key}`, '');
    // }

    // const resetCustomFields = () => {
    //     setValue('CidadeDaEmpresa', '')
    //     setValue('EstadoDaEmpresa', '')
    //     setValue('NumeroDeParcelas', '')
    //     setValue('Carencia', '')
    //     // setValue('Rg', '')
    //     // reset({ SexoConjuge: '' })
    //     // reset({ Sexo: '' })
    //     reset({ Agencia: '' })
    //     if (spanRef.current?.textContent && spanRef.current.textContent !== initialValue) 
    //         spanRef.current.textContent = initialValue   
    // }

    // const resetAllFields = () => {
    //     resetPartnerFields();
    //     resetCustomFields();
    //     setRadioAndCheckboxDefaultValues();
    // }

    // Submit
    const onSubmit = (data) => {
        setLoading(true)

        const newSocios = []
        newSocios.push({
            Cpf: data.Cpf.replace(/\D/g, ''),
            Nome: data.Nome,
            Email: data.Email,
            Telefone: data.Telefone,
            DataDeNascimento: data.DataDeNascimento,
            NomeDaMae: data.NomeDaMae,
            Rg: data.Rg.replace(/\D/g, ''),
            Sexo: Number(data.Sexo),
            Complemento: data.Complemento,
            ComplementoConjuge: data.ComplementoConjuge,
            Naturalidade: data.Naturalidade,
            ProfissaoConjuge: data.ProfissaoConjuge,
            RendaConjuge: data.RendaConjuge,
            Cep: data.Cep.replace(/\D/g, ''),
            Endereco: data.Endereco,
            Bairro: data.Bairro,
            Estado: data.Estado,
            Cidade: data.Cidade,
            Participacao: data.Participacao,
            EstadoCivil: Number(data.EstadoCivil),
            CpfConjuge: data.CpfConjuge?.replace(/\D/g, ''),
            NomeConjuge: data.NomeConjuge,
            EmailConjuge: data.EmailConjuge,
            TelefoneConjuge: data.TelefoneConjuge,
            DataDeNascimentoConjuge: data.DataDeNascimentoConjuge,
            NomeDaMaeConjuge: data.NomeDaMaeConjuge,
            RgConjuge: data.RgConjuge,
            SexoConjuge: Number(data.SexoConjuge),
            NaturalidadeConjuge: data.NaturalidadeConjuge,
            CepConjuge: data.CepConjuge?.replace(/\D/g, ''),
            EnderecoConjuge: data.EnderecoConjuge,
            BairroConjuge: data.BairroConjuge,
            EstadoConjuge: data.EstadoConjuge,
            CidadeConjuge: data.CidadeConjuge,
        })

        const socios = watch(`socios`)
        for (let i = 0; i < socios?.length || 0; i++) {
            newSocios.push({
                Cpf: data.socios[i].Cpf.replace(/\D/g, ''),
                Nome: data.socios[i].Nome,
                Email: data.socios[i].Email,
                Telefone: data.socios[i].Telefone,
                DataDeNascimento: data.socios[i].DataDeNascimento,
                NomeDaMae: data.socios[i].NomeDaMae,
                Rg: data.socios[i].Rg.replace(/\D/g, ''),
                Sexo: Number(data.socios[i].Sexo),
                Naturalidade: data.socios[i].Naturalidade,
                Cep: data.socios[i].Cep.replace(/\D/g, ''),
                Endereco: data.socios[i].Endereco,
                Complemento: data.socios[i].Complemento,
                ComplementoConjuge: data.socios[i].ComplementoConjuge,
                ProfissaoConjuge: data.socios[i].ProfissaoConjuge,
                RendaConjuge: data.socios[i].RendaConjuge,
                Bairro: data.socios[i].Bairro,
                Estado: data.socios[i].Estado,
                Cidade: data.socios[i].Cidade,
                Participacao: data.socios[i].Participacao,
                EstadoCivil: Number(data.socios[i].EstadoCivil),
                CpfConjuge: data.socios[i].CpfConjuge?.replace(/\D/g, ''),
                NomeConjuge: data.socios[i].NomeConjuge,
                EmailConjuge: data.socios[i].EmailConjuge,
                TelefoneConjuge: data.socios[i].TelefoneConjuge,
                DataDeNascimentoConjuge: data.socios[i].DataDeNascimentoConjuge,
                NomeDaMaeConjuge: data.socios[i].NomeDaMaeConjuge,
                RgConjuge: data.socios[i].RgConjuge.replace(/\D/g, ''),
                SexoConjuge: Number(data.socios[i].SexoConjuge),
                NaturalidadeConjuge: data.socios[i].NaturalidadeConjuge,
                CepConjuge: data.socios[i].CepConjuge.replace(/\D/g, ''),
                EnderecoConjuge: data.socios[i].EnderecoConjuge,
                BairroConjuge: data.socios[i].BairroConjuge,
                EstadoConjuge: data.socios[i].EstadoConjuge,
                CidadeConjuge: data.socios[i].CidadeConjuge,
            })
        }

        const body = {
            RazaoSocial: data.RazaoSocial,
            Cnpj: data.Cnpj.replace(/\D/g, ''),
            TelefoneDaEmpresa: data.TelefoneDaEmpresa,
            CepDaEmpresa: data.CepDaEmpresa.replace(/\D/g, ''),
            EnderecoDaEmpresa: data.EnderecoDaEmpresa,
            BairroDaEmpresa: data.BairroDaEmpresa,
            EstadoDaEmpresa: data.EstadoDaEmpresa,
            CidadeDaEmpresa: data.CidadeDaEmpresa,
            EmailDaEmpresa: data.EmailDaEmpresa,
            FaturamentoAnual: currencyToNumber(data.FaturamentoAnual),
            PorteDaEmpresa: data.PorteDaEmpresa,
            AtividadeDaEmpresa: Number(data.AtividadeDaEmpresa),
            Agencia: data.Agencia,
            InstituicaoFinanceira: data.Agencia ? 27 : "",
            ValorCreditoPretendido: currencyToNumber(data.ValorCreditoPretendido),
            NumeroDeParcelas: data.NumeroDeParcelas,
            Carencia: data.Carencia,
            FinalidadeDoCredito: data.FinalidadeDoCredito,
            NumeroAtualFuncionario: data.NumeroAtualFuncionario,
            ProvaveisEmpregos: data.ProvaveisEmpregos,
            Base64DoArquivo: data.Base64DoArquivo,
            ExtensaoDoArquivo: '.pdf',
            Socios: newSocios,
        }

        const apiUrl = `${process.env.REACT_APP_GARANTIMAIS_API_URL}Cadastro/Associados/PanambiRsGaranti`
        const apiKey = process.env.REACT_APP_GARANTIMAIS_API_KEY
        // const fakeApi = `http://localhost:3001/posts/`

        if (sum != 100) {
            alert(`A soma de participação dos sócios não pode ser diferente de 100%, valor total está em: ${sum}%`)
            return
        }

        if (megabytes > 2097152) {
            setLoading(false)
            alert(`Tamanho máximo 2 MB, selecione outro arquivo!`)
            return
        }

        axios.post(apiUrl, body, { headers: { Authorization: apiKey } }).then(({ status }) => {
            /**
             * Se a requisição for bem-sucedida (status entre 200 e 209),
             * chama a função sendEmail passando os dados e socios como argumento, 
             * depois define a mensagem de sucesso e atualiza os estados de loading e submissão.
             */
            sendEmail(data, socios)
            if (status >= 200 && status <= 299)
                setMessage(`Seus dados foram enviados com sucesso!`)
            setLoading(false)
            setSubmited(true)
        }).catch((error) => {
            // Se houver um erro na requisição, trata o erro.
            setLoading(false)
            setSubmited(true)
            if (error.response) {
                /**
                 * Se a resposta do servidor estiver presente no erro, 
                 * verifica o status code para determinar a mensagem apropriada
                 */
                if (error.response.status >= 400 && error.response.status <= 499)
                    setMessage(error.response?.data?.ErrorMessage || `Aconteceu algo inesperado`)
                else if (error.response.status >= 500 && error.response.status <= 599)
                    setMessage(`Erro interno no servidor! ${error.response?.data?.ErrorMessage}`)
            }
        })
    }

    if (submited)
        setTimeout(() => setSubmited(false), 3000)

    // Soma das participações
    const sociedade = watch('Participacao')
    const socios = watch('socios', [])
    let sum = 0
    sum += Number(sociedade) ?? 0;
    for (let i = 0; i < socios?.length || 0; i++)
        sum += Number(socios[i].Participacao) ?? 0

    return (
        <section id="sectionForm">
            <header className='container'>
                <h2
                    style={
                        {
                            color: '#fff',
                            fontStyle: 'italic',
                            fontWeight: 'normal',
                            fontSize: '2.125rem',
                        }
                    }
                >
                    Solicite o crédito
                    <br />
                    <strong>para sua empresa!</strong>
                </h2>
                <p
                    style={
                        {
                            color: '#fff',
                            fontWeight: 'normal',
                            textAlign: 'right'
                        }
                    }
                >Aproveite a chance de construir novos projetos e aprimorar os seus negócios. <br />
                    <strong>Preencha o formulário para receber mais informações</strong>.</p>
            </header>

            <form onSubmit={handleSubmit(onSubmit)}>
                <PjForm
                    register={register}
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                />

                <UploadForm
                    register={register}
                    setValue={setValue}
                    errors={errors}
                    watch={watch}
                    propsSpanRef={getSelectedFileRef}
                    setInitialValue={initialValue}
                />

                <PfForm
                    register={register}
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                />
                <SocioForm
                    register={register}
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                />

                <CreditoForm
                    register={register}
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                />
                <TermosForm
                    register={register}
                    errors={errors}
                />

                {/* Spinner - Submit button */}
                {
                    loading === false ? (
                        <button type="submit">
                            Enviar formulário
                        </button>
                    )
                        :
                        (
                            <button id='loading-content'>
                                <span className="loading animate-loading"></span>
                            </button>
                        )
                }
            </form>
            {submited && <span className='showMessage'>{message}</span>}
            {/* <DevTool
                // Componente DevTool para depuração
                control={control}
            /> */}
        </section>
    )
}

export default Form