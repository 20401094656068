import React from 'react'
import ico1 from '../assets/imgs/ico-1.png'
import ico2 from '../assets/imgs/ico-2.png'
import ico3 from '../assets/imgs/ico-3.png'

const BxSectionB = () => {
    return (
        <>
            <section id='sectionB'>
                <div className="container">
                    <h2
                        style={
                            {
                                color: '#FFFFFF',
                                fontWeight: 'normal',
                                fontSize: '2.125rem',
                                textAlign: 'right'
                            }
                        }>
                        Soluções em crédito <br />
                        <strong
                            style={
                                {
                                    fontStyle: 'italic',
                                    fontSize: '3.5rem',
                                }
                            }>
                            para sua empresa
                        </strong>
                    </h2>
                    <div className="cards">
                        <figure>
                            <img src={ico1} alt="Crédio para qualquer necessidade" />
                            <figcaption>Crédito para <br /><strong>qualquer necessidade</strong></figcaption>
                        </figure>
                        <figure>
                            <img src={ico2} alt="Condições exclusivas" />
                            <figcaption>Condições <br /><strong>exclusivas</strong></figcaption>
                        </figure>
                        <figure>
                            <img src={ico3} alt="Valor disponível em poucos dias" />
                            <figcaption>Valor disponível <br /><strong>em poucos dias</strong></figcaption>
                        </figure>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BxSectionB