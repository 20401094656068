import React from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { maskCpf, maskPhoneNumber, maskCep, maskData, maskCurrency, maskRemoveCharactersNotNumeric, maskRemoveLetters } from '../utils/masks'
import { clearPartnersFields } from '../utils/clearCustomFields'

const SocioForm = ({ register, watch, setValue, errors }) => {

  const { control } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'socios'
  })

  return (
    <>
      <div className="container socioForm">
        {fields.map((socio, index) => {
          const estadoCivil = watch(`socios.${index}.EstadoCivil`)
          return (
            <>
              <div key={socio.id} className='socioId'>
                <h2
                  style={
                    {
                      color: '#FFFFFF',
                      fontWeight: 'normal',
                      fontSize: '1.2rem',
                      marginBottom: '7.5px',
                    }
                  }
                >
                  {`Sócio ${index + 1} - Representante / Pessoa Física`}
                </h2>

                <button
                  id='remove-btn'
                  type="button"
                  onClick={() => {
                    remove(index)
                    setValue(`socios`, [])
                  }}
                >
                  Remover
                </button>

                {/* Duplicar sócio representante / Pessoa física */}
                <div className="socioRepresentantePf grid">

                  {/* Row 1 */}

                  <div
                    className='box cpfPf'
                  >
                    <input
                      type="text"
                      placeholder='CPF do Sócio'
                      {...register(`socios.${index}.Cpf`, { required: true })}
                      onChange={(e) => setValue(`socios.${index}.Cpf`, maskCpf(e.target.value))}
                    />
                    {errors.Cpf && <span className="erroForm">&gt; Obrigatório</span>}
                  </div>

                  <div
                    className='box nomePf'
                  >
                    <input
                      type="text"
                      placeholder='Nome do Sócio'
                      {...register(`socios.${index}.Nome`, { required: true })}
                      onChange={(e) => setValue(`socios.${index}.Nome`, e.target.value)}
                    />
                    {errors.Nome && <span className="erroForm">&gt; Obrigatório</span>}
                  </div>

                  <div
                    className='box emailPf'
                  >
                    <input
                      type="text"
                      placeholder='E-mail do Sócio'
                      {...register(`socios.${index}.Email`, { required: true })}
                      onChange={(e) => setValue(`socios.${index}.Email`, e.target.value)}
                    />
                    {errors.Email && <span className="erroForm">&gt; Obrigatório</span>}
                  </div>

                  {/* Row 2 */}

                  <div
                    className='box telefonePf'
                  >
                    <input
                      type="text"
                      placeholder='Telefone do Sócio'
                      {...register(`socios.${index}.Telefone`, { required: true })}
                      onChange={(e) => setValue(`socios.${index}.Telefone`, maskPhoneNumber(e.target.value))}
                    />
                    {errors.Telefone && <span className="erroForm">&gt; Obrigatório</span>}
                  </div>

                  <div
                    className='box dataNascimentoPf'
                  >
                    <input
                      type="text"
                      placeholder='Data de Nascimento'
                      {...register(`socios.${index}.DataDeNascimento`, { required: true })}
                      onChange={(e) => setValue(`socios.${index}.DataDeNascimento`, maskData(e.target.value))}
                    />
                    {errors.DataDeNascimento && <span className="erroForm">&gt; Obrigatório</span>}
                  </div>

                  <div
                    className='box nomeMaePf'
                  >
                    <input
                      type="text"
                      placeholder='Nome da Mãe'
                      {...register(`socios.${index}.NomeDaMae`, { required: true })}
                      onChange={(e) => setValue(`socios.${index}.NomeDaMae`, e.target.value)}
                    />
                    {errors.NomeDaMae && <span className='erroForm'>&gt; Obrigatório</span>}
                  </div>

                  {/* Row 3 */}

                  <div
                    className='box rgPf'
                  >
                    <input
                      type="text"
                      placeholder='RG'
                      {...register(`socios.${index}.Rg`, { required: true })}
                      onChange={(e) => setValue(`socios.${index}.Rg`, e.target.value.replace(/[\D]/g, ''))}
                    />
                    {errors.Rg && <span className='erroForm'>&gt; Obrigatório</span>}
                  </div>

                  <div
                    className='box sexoPf'
                  >
                    <select
                      {...register(`socios.${index}.Sexo`, { required: true })}
                      onChange={(e) => setValue(`socios.${index}.Sexo`, e.target.value)}
                    >
                      <option value='' disabled selected>Sexo</option>
                      <option value="0">Masculino</option>
                      <option value="1">Feminino</option>
                      <option value="2">Outro</option>
                    </select>
                    {errors.Sexo && <span className='erroForm'>&gt; Obrigatório</span>}
                  </div>

                  <div
                    className='box naturalidadePf'
                  >
                    <input
                      type="text"
                      placeholder='Naturalidade'
                      {...register(`socios.${index}.Naturalidade`, { required: true })}
                      onChange={(e) => setValue(`socios.${index}.Naturalidade`, e.target.value)}
                    />
                    {errors.Naturalidade && <span className='erroForm'>&gt; Obrigatório</span>}
                  </div>

                  <div className="box cepPf">
                    <input
                      type="text"
                      placeholder='Cep'
                      {...register(`socios.${index}.Cep`, { required: true })}
                      onChange={(e) => setValue(`socios.${index}.Cep`, maskCep(e.target.value))}
                    />
                    {errors.Cep && <span className='erroForm'>&gt; Obrigatório</span>}
                  </div>

                  <div
                    className='box enderecoPf'
                  >
                    <input
                      type="text"
                      placeholder='Endereço'
                      {...register(`socios.${index}.Endereco`, { required: true })}
                      onChange={(e) => setValue(`socios.${index}.Endereco`, e.target.value)}
                    />
                    {errors.Endereco && <span className='erroForm'>&gt; Obrigatório</span>}
                  </div>

                  {/* Row 4*/}

                  <div
                    className='box bairroPf'
                  >
                    <input
                      type="text"
                      placeholder='Bairro'
                      {...register(`socios.${index}.Bairro`, { required: true })}
                      onChange={(e) => setValue(`socios.${index}.Bairro`, e.target.value)}
                    />
                    {errors.Bairro && <span className='erroForm'>&gt; Obrigatório</span>}
                  </div>

                  <div
                    className='box estadoPf'
                  >
                    <input
                      type="text"
                      placeholder='Estado'
                      title='2 dígitos'
                      maxLength='2'
                      {...register(`socios.${index}.Estado`, { required: true })}
                      onChange={(e) => setValue(`socios.${index}.Estado`, maskRemoveCharactersNotNumeric(e.target.value))}
                    />
                    {errors.Estado && <span className='erroForm'>&gt; Obrigatório</span>}
                  </div>

                  <div
                    className='box cidadePf'
                  >
                    <input
                      type="text"
                      placeholder='Cidade'
                      {...register(`socios.${index}.Cidade`, { required: true })}
                      onChange={(e) => setValue(`socios.${index}.Cidade`, e.target.value)}
                    />
                    {errors.Cidade && <span className='erroForm'>&gt; Obrigatório</span>}
                  </div>

                  <div
                    className='box sociedadePf'
                  >
                    <input
                      type="text"
                      placeholder='% Sociedade'
                      maxLength={'3'}
                      title='Somente números'
                      {...register(`socios.${index}.Participacao`, { required: true })}
                      onChange={(e) => setValue(`socios.${index}.Participacao`, maskRemoveLetters(e.target.value))}
                    />
                    {errors.Participacao && <span className='erroForm'>&gt; Obrigatório</span>}
                  </div>

                  {/* Row 5 */}

                  <div className='box estadoCivilPf bgColorElement'>
                    <label>
                      <span>Estado Civil</span>
                    </label>
                    <div>
                      <label>
                        Solteiro
                        <input
                          className='boxSolteiro'
                          type="radio"
                          name='estadoCivil'
                          value='1'
                          defaultChecked='true'
                          {...register(`socios.${index}.EstadoCivil`)}
                          onChange={(e) => {
                            setValue(`socios.${index}.EstadoCivil`, e.target.value)
                            clearPartnersFields(setValue, index)
                          }}
                        />
                      </label>
                      <label>Casado(a)
                        <input
                          className='boxCasado'
                          type="radio"
                          name='estadoCivil'
                          value='0'
                          {...register(`socios.${index}.EstadoCivil`)}
                          onChange={(e) => {
                            setValue(`socios.${index}.EstadoCivil`, e.target.value)
                            clearPartnersFields(setValue, index)
                          }}
                        />
                      </label>
                      <label>
                        União Estável
                        <input
                          className='boxUniaoEstavel'
                          type="radio"
                          name='estadoCivil'
                          value='5'
                          {...register(`socios.${index}.EstadoCivil`)}
                          onChange={(e) => {
                            setValue(`socios.${index}.EstadoCivil`, e.target.value)
                            clearPartnersFields(setValue, index)
                          }}
                        />
                      </label>
                      <label>
                        Divorciado(a)
                        <input
                          className='boxDivorciado'
                          type="radio"
                          name='estadoCivil'
                          value='3'
                          {...register(`socios.${index}.EstadoCivil`)}
                          onChange={(e) => {
                            setValue(`socios.${index}.EstadoCivil`, e.target.value)
                            clearPartnersFields(setValue, index)
                          }}
                        />
                      </label>
                      <label>
                        Viúvo(a)
                        <input
                          className='boxViuvo'
                          type="radio"
                          name='estadoCivil'
                          value='2'
                          {...register(`socios.${index}.EstadoCivil`)}
                          onChange={(e) => {
                            setValue(`socios.${index}.EstadoCivil`, e.target.value)
                            clearPartnersFields(setValue, index)
                          }}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                {/* Duplicar cônjuge representante / Pessoa física */}
                {(estadoCivil === '0' || estadoCivil === '5') && (
                  <div id="conjugeRepresentantePf" style={{ marginTop: '50px' }}>
                    <h2
                      style={
                        {
                          color: '#FFFFFF',
                          fontWeight: 'normal',
                          fontSize: '1.2rem'
                        }
                      }
                    >
                      {`Cônjuge ${index + 1} - Representante / Pessoa Física`}
                    </h2>
                    <div className="conjugeRepresentantePf grid">

                      {/* Row 1 */}

                      <div
                        className='box cpfPf'
                      >
                        <input
                          type="text"
                          placeholder='CPF Cônjuge'
                          {...register(`socios.${index}.CpfConjuge`, { required: true })}
                          onChange={(e) => setValue(`socios.${index}.CpfConjuge`, maskCpf(e.target.value))}
                        />
                        {errors.CpfConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
                      </div>

                      <div
                        className='box nomePf'
                      >
                        <input
                          type="text"
                          placeholder='Nome Cônjuge'
                          {...register(`socios.${index}.NomeConjuge`, { required: true })}
                          onChange={(e) => setValue(`socios.${index}.NomeConjuge`, e.target.value)}
                        />
                        {errors.NomeConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
                      </div>

                      <div
                        className='box emailPf'
                      >
                        <input
                          type="text"
                          placeholder='E-mail Cônjuge'
                          {...register(`socios.${index}.EmailConjuge`, { required: true })}
                          onChange={(e) => setValue(`socios.${index}.EmailConjuge`, e.target.value)}
                        />
                        {errors.EmailConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
                      </div>

                      {/* Row 2 */}

                      <div
                        className='box telefonePf'
                      >
                        <input
                          type="text"
                          placeholder='Telefone Cônjuge'
                          {...register(`socios.${index}.TelefoneConjuge`, { required: true })}
                          onChange={(e) => setValue(`socios.${index}.TelefoneConjuge`, maskPhoneNumber(e.target.value))}
                        />
                        {errors.TelefoneConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
                      </div>

                      <div
                        className='box dataNascimentoPf'
                      >
                        <input
                          type="text"
                          placeholder='Data Cônjuge'
                          {...register(`socios.${index}.DataDeNascimentoConjuge`, { required: true })}
                          onChange={(e) => setValue(`socios.${index}.DataDeNascimentoConjuge`, maskData(e.target.value))}
                        />
                        {errors.DataDeNascimentoConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
                      </div>

                      <div
                        className='box nomeMaePf'
                      >
                        <input
                          type="text"
                          placeholder='Nome da Mãe Cônjuge'
                          {...register(`socios.${index}.NomeDaMaeConjuge`, { required: true })}
                          onChange={(e) => setValue(`socios.${index}.NomeDaMaeConjuge`, e.target.value)}
                        />
                        {errors.NomeDaMaeConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
                      </div>

                      {/* Row 3 */}

                      <div
                        className='box rgPf'
                      >
                        <input
                          type="text"
                          placeholder='RG Cônjuge'
                          {...register(`socios.${index}.RgConjuge`, { required: true })}
                          onChange={(e) => setValue(`socios.${index}.RgConjuge`, e.target.value.replace(/[\D]/g, ''))}
                        />
                        {errors.RgConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
                      </div>

                      <div
                        className='box sexoPf'
                      >
                        <select
                          {...register(`socios.${index}.SexoConjuge`, { required: true })}
                          onChange={(e) => setValue(`socios.${index}.SexoConjuge`, e.target.value)}
                        >
                          <option value="" disabled selected>Sexo Cônjugue</option>
                          <option value="0">Masculino</option>
                          <option value="1">Feminino</option>
                          <option value="2">Outro</option>
                        </select>
                        {errors.SexoConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
                      </div>

                      <div
                        className='box naturalidadePf'
                      >
                        <input
                          type="text"
                          placeholder='Naturalidade Cônjuge'
                          {...register(`socios.${index}.NaturalidadeConjuge`, { required: true })}
                          onChange={(e) => setValue(`socios.${index}.NaturalidadeConjuge`, e.target.value)}
                        />
                        {errors.NaturalidadeConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
                      </div>

                      <div
                        className='box cepPf'
                      >
                        <input
                          type="text"
                          placeholder='Cep Cônjuge'
                          {...register(`socios.${index}.CepConjuge`, { required: true })}
                          onChange={(e) => setValue(`socios.${index}.CepConjuge`, maskCep(e.target.value))}
                        />
                        {errors.CepConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
                      </div>

                      <div
                        className='box enderecoPf'
                      >
                        <input
                          type="text"
                          placeholder='Endereço Cônjuge'
                          {...register(`socios.${index}.EnderecoConjuge`, { required: true })}
                          onChange={(e) => setValue(`socios.${index}.EnderecoConjuge`, e.target.value)}
                        />
                        {errors.EnderecoConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
                      </div>

                      {/* Row 4*/}

                      <div
                        className='box bairroPf'
                      >
                        <input
                          type="text"
                          placeholder='Bairro Cônjuge'
                          {...register(`socios.${index}.BairroConjuge`, { required: true })}
                          onChange={(e) => setValue(`socios.${index}.BairroConjuge`, e.target.value)}
                        />
                        {errors.BairroConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
                      </div>

                      <div
                        className='box estadoPf'
                      >
                        <input
                          type="text"
                          placeholder='Estado Cônjuge'
                          maxLength={'2'}
                          title='2 dígitos'
                          {...register(`socios.${index}.EstadoConjuge`, { required: true })}
                          onChange={(e) => setValue(`socios.${index}.EstadoConjuge`, maskRemoveCharactersNotNumeric(e.target.value))}
                        />
                        {errors.EstadoConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
                      </div>

                      <div
                        className='box cidadePf'
                      >
                        <input
                          type="text"
                          placeholder='Cidade Cônjuge'
                          {...register(`socios.${index}.CidadeConjuge`, { required: true })}
                          onChange={(e) => setValue(`socios.${index}.CidadeConjuge`, e.target.value)}
                        />
                        {errors.CidadeConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
                      </div>

                      <div
                        className='box complementoPf'
                      >
                        <input
                          type="text"
                          placeholder='Complemento Cônjuge'
                          {...register(`socios.${index}.ComplementoConjuge`, { required: true })}
                          onChange={(e) => setValue(`socios.${index}.ComplementoConjuge`, e.target.value)}
                        />
                        {errors.ComplementoConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
                      </div>

                      <div
                        className='box profissaoPf'
                      >
                        <input
                          type="text"
                          placeholder='Profissão Cônjuge'
                          {...register(`socios.${index}.ProfissaoConjuge`, { required: true })}
                          onChange={(e) => setValue(`socios.${index}.ProfissaoConjuge`, e.target.value)}
                        />
                        {errors.ProfissaoConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
                      </div>

                      <div
                        className='box rendaPf'
                      >
                        <input
                          type="text"
                          placeholder='Renda Cônjuge'
                          {...register(`socios.${index}.RendaConjuge`, { required: true })}
                          onChange={(e) => setValue(`socios.${index}.RendaConjuge`, maskCurrency(e.target.value))}
                        />
                        {errors.RendaConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )
        })}

        <button
          id='add-btn'
          type="button"
          onClick={() =>
            append(
              {
                Cpf: '',
                Nome: '',
                Email: '',
                Telefone: '',
                DataDeNascimento: '',
                NomeDaMae: '',
                Rg: '',
                Sexo: '',
                Naturalidade: '',
                Cep: '',
                Endereco: '',
                Bairro: '',
                Estado: '',
                Cidade: '',
                Participacao: '',
                EstadoCivil: '1'
              })
          }
        >
          Incluir sócio
        </button>
      </div>
    </>
  )
}

export default SocioForm