import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './App';
import { PopupContextProvider } from './context/PopupContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PopupContextProvider>
        <App />
    </PopupContextProvider>
  </React.StrictMode>
);

