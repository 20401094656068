/* eslint-disable */
import React from 'react'
import { maskCpf, maskPhoneNumber, maskCep, maskData, maskCurrency, maskRemoveCharactersNotNumeric, maskRemoveLetters } from '../utils/masks'
import { clearSpouseFields } from '../utils/clearCustomFields'

const PfForm = ({ register, setValue, watch, errors }) => {

  // Verifica o estado civil 
  const estadoCivil = watch('EstadoCivil')
  
  return (
    <div className="container pfForm">
      <h2
        style={
          {
            color: '#FFFFFF',
            fontWeight: 'normal',
            fontSize: '1.2rem',
            marginBottom: '7.5px',
          }
        }
      >
        Sócio Representante / Pessoa Física
      </h2>

      {/* Sócio representante / Pessoa física */}
      <div className="socioRepresentantePf grid">

        {/* Row 1 */}

        <div
          className='box cpfPf'
        >
          <input
            type="text"
            placeholder='CPF do Sócio'
            {...register('Cpf', { required: true })}
            onChange={(e) => setValue('Cpf', maskCpf(e.target.value))}
          />
          {errors.Cpf && <span className="erroForm">&gt; Obrigatório</span>}
        </div>

        <div
          className='box nomePf'
        >
          <input
            type="text"
            placeholder='Nome do Sócio'
            {...register('Nome', { required: true })}
            onChange={(e) => setValue('Nome', e.target.value)}
          />
          {errors.Nome && <span className="erroForm">&gt; Obrigatório</span>}
        </div>

        <div
          className='box emailPf'
        >
          <input
            type="text"
            placeholder='E-mail do Sócio'
            {...register('Email', { required: true })}
            onChange={(e) => setValue('Email', e.target.value)}
          />
          {errors.Email && <span className="erroForm">&gt; Obrigatório</span>}
        </div>

        {/* Row 2 */}

        <div
          className='box telefonePf'
        >
          <input
            type="text"
            placeholder='Telefone do Sócio'
            {...register('Telefone', { required: true })}
            onChange={(e) => setValue('Telefone', maskPhoneNumber(e.target.value))}
          />
          {errors.Telefone && <span className="erroForm">&gt; Obrigatório</span>}
        </div>

        <div
          className='box dataNascimentoPf'
        >
          <input
            type="text"
            placeholder='Data de Nascimento'
            {...register('DataDeNascimento', { required: true })}
            onChange={(e) => setValue('DataDeNascimento', maskData(e.target.value))}
          />
          {errors.DataDeNascimento && <span className="erroForm">&gt; Obrigatório</span>}
        </div>

        <div
          className='box nomeMaePf'
        >
          <input
            type="text"
            placeholder='Nome da Mãe'
            {...register('NomeDaMae', { required: true })}
            onChange={(e) => setValue('NomeDaMae', e.target.value)}
          />
          {errors.NomeDaMae && <span className='erroForm'>&gt; Obrigatório</span>}
        </div>

        {/* Row 3 */}

        <div
          className='box rgPf'
        >
          <input
            type="text"
            placeholder='RG'
            {...register('Rg', { required: true })}
            onChange={(e) => setValue('Rg', e.target.value.replace(/[\D]/g, ''))}
          />
          {errors.Rg && <span className='erroForm'>&gt; Obrigatório</span>}
        </div>

        <div
          className='box sexoPf'
        >
          <select
            {...register('Sexo', { required: true })}
            onChange={(e) => setValue('Sexo', e.target.value)}
          >
            <option value="" disabled selected>Sexo</option>
            <option value="0">Masculino</option>
            <option value="1">Feminino</option>
            <option value="2">Outro</option>
          </select>
          {errors.Sexo && <span className='erroForm'>&gt; Obrigatório</span>}
        </div>

        <div
          className='box naturalidadePf'
        >
          <input
            type="text"
            placeholder='Naturalidade'
            {...register('Naturalidade', { required: true })}
            onChange={(e) => setValue('Naturalidade', e.target.value)}
          />
          {errors.Naturalidade && <span className='erroForm'>&gt; Obrigatório</span>}
        </div>

        <div className="box cepPf">
          <input
            type="text"
            placeholder='Cep'
            {...register('Cep', { required: true })}
            onChange={(e) => setValue('Cep', maskCep(e.target.value))}
          />
          {errors.Cep && <span className='erroForm'>&gt; Obrigatório</span>}
        </div>

        <div
          className='box enderecoPf'
        >
          <input
            type="text"
            placeholder='Endereço'
            {...register('Endereco', { required: true })}
            onChange={(e) => setValue('Endereco', e.target.value)}
          />
          {errors.Endereco && <span className='erroForm'>&gt; Obrigatório</span>}
        </div>

        {/* Row 4*/}

        <div
          className='box bairroPf'
        >
          <input
            type="text"
            placeholder='Bairro'
            {...register('Bairro', { required: true })}
            onChange={(e) => setValue('Bairro', e.target.value)}
          />
          {errors.Bairro && <span className='erroForm'>&gt; Obrigatório</span>}
        </div>

        <div
          className='box estadoPf'
        >
          <input
            type="text"
            placeholder='Estado'
            title='2 dígitos'
            maxLength='2'
            {...register('Estado', { required: true })}
            onChange={(e) => setValue('Estado', maskRemoveCharactersNotNumeric(e.target.value))}
          />
          {errors.Estado && <span className='erroForm'>&gt; Obrigatório</span>}
        </div>

        <div
          className='box cidadePf'
        >
          <input
            type="text"
            placeholder='Cidade'
            {...register('Cidade', { required: true })}
            onChange={(e) => setValue('Cidade', e.target.value)}
          />
          {errors.Cidade && <span className='erroForm'>&gt; Obrigatório</span>}
        </div>

        <div
          className='box sociedadePf'
        >
          <input
            type="text"
            placeholder='% Sociedade'
            maxLength={'3'}
            title='Somente números'
            {...register('Participacao', { required: true })}
            onChange={(e) => setValue('Participacao', maskRemoveLetters(e.target.value))}
          />
          {errors.Participacao && <span className='erroForm'>&gt; Obrigatório</span>}
        </div>

        {/* Row 5 */}

        <div className='box estadoCivilPf bgColorElement'>
          <label>
            <span>Estado Civil</span>
          </label>
          <div>
            <label>
              Solteiro
              <input
                className='boxSolteiro'
                type="radio"
                name='estadoCivil'
                value='1'
                defaultChecked={true}
                {...register('EstadoCivil')}
                onChange={(e) => {
                  setValue('EstadoCivil', e.target.value)
                  clearSpouseFields(setValue)
                }}
              />
            </label>
            <label>Casado(a)
              <input
                className='boxCasado'
                type="radio"
                name='estadoCivil'
                value='0'
                {...register('EstadoCivil')}
                onChange={(e) => {
                  setValue('EstadoCivil', e.target.value)
                  clearSpouseFields(setValue)
                }}
              />
            </label>
            <label>
              União Estável
              <input
                className='boxUniaoEstavel'
                type="radio"
                name='estadoCivil'
                value='5'
                {...register('EstadoCivil')}
                onChange={(e) => {
                  setValue('EstadoCivil', e.target.value)
                  clearSpouseFields(setValue)
                }}
              />
            </label>
            <label>
              Divorciado(a)
              <input
                className='boxDivorciado'
                type="radio"
                name='estadoCivil'
                value='3'
                {...register('EstadoCivil')}
                onChange={(e) => {
                  setValue('EstadoCivil', e.target.value)
                  clearSpouseFields(setValue)
                }}
              />
            </label>
            <label>
              Viúvo(a)
              <input
                className='boxViuvo'
                type="radio"
                name='estadoCivil'
                value='2'
                {...register('EstadoCivil')}
                onChange={(e) => {
                  setValue('EstadoCivil', e.target.value)
                  clearSpouseFields(setValue)
                }}
              />
            </label>
          </div>
        </div>
      </div>

      {/* Cônjuge representante / Pessoa física */}
      {(estadoCivil === '0' || estadoCivil === '5') && (
        <div id="conjugeRepresentantePf">
          <h2
            style={
              {
                color: '#FFFFFF',
                fontWeight: 'normal',
                fontSize: '1.2rem'
              }
            }
          >
            Cônjuge Representante / Pessoa Física
          </h2>
          <div className="conjugeRepresentantePf grid">

            {/* Row 1 */}

            <div
              className='box cpfPf'
            >
              <input
                type="text"
                placeholder='CPF Cônjuge'
                {...register('CpfConjuge', { required: true })}
                onChange={(e) => setValue('CpfConjuge', maskCpf(e.target.value))}
              />
              {errors.CpfConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
              className='box nomePf'
            >
              <input
                type="text"
                placeholder='Nome Cônjuge'
                {...register('NomeConjuge', { required: true })}
                onChange={(e) => setValue('NomeConjuge', e.target.value)}
              />
              {errors.NomeConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
              className='box emailPf'
            >
              <input
                type="text"
                placeholder='E-mail Cônjuge'
                {...register('EmailConjuge', { required: true })}
                onChange={(e) => setValue('EmailConjuge', e.target.value)}
              />
              {errors.EmailConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            {/* Row 2 */}

            <div
              className='box telefonePf'
            >
              <input
                type="text"
                placeholder='Telefone Cônjuge'
                {...register('TelefoneConjuge', { required: true })}
                onChange={(e) => setValue('TelefoneConjuge', maskPhoneNumber(e.target.value))}
              />
              {errors.TelefoneConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
              className='box dataNascimentoPf'
            >
              <input
                type="text"
                placeholder='Data Cônjuge'
                {...register('DataDeNascimentoConjuge', { required: true })}
                onChange={(e) => setValue('DataDeNascimentoConjuge', maskData(e.target.value))}
              />
              {errors.DataDeNascimentoConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
              className='box nomeMaePf'
            >
              <input
                type="text"
                placeholder='Nome da Mãe Cônjuge'
                {...register('NomeDaMaeConjuge', { required: true })}
                onChange={(e) => setValue('NomeDaMaeConjuge', e.target.value)}
              />
              {errors.NomeDaMaeConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            {/* Row 3 */}

            <div
              className='box rgPf'
            >
              <input
                type="text"
                placeholder='RG Cônjuge'
                {...register('RgConjuge', { required: true })}
                onChange={(e) => setValue('RgConjuge', e.target.value.replace(/[\D]/g, ''))}
              />
              {errors.RgConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
              className='box sexoPf'
            >
              <select
                {...register('SexoConjuge', { required: true })}
                onChange={(e) => setValue('SexoConjuge', e.target.value)}
              >
                <option value="" disabled selected>Sexo Cônjugue</option>
                <option value="0">Masculino</option>
                <option value="1">Feminino</option>
                <option value="2">Outro</option>
              </select>
              {errors.SexoConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
              className='box naturalidadePf'
            >
              <input
                type="text"
                placeholder='Naturalidade Cônjuge'
                {...register('NaturalidadeConjuge', { required: true })}
                onChange={(e) => setValue('NaturalidadeConjuge', e.target.value)}
              />
              {errors.NaturalidadeConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
              className='box cepPf'
            >
              <input
                type="text"
                placeholder='Cep Cônjuge'
                {...register('CepConjuge', { required: true })}
                onChange={(e) => setValue('CepConjuge', maskCep(e.target.value))}
              />
              {errors.CepConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
              className='box enderecoPf'
            >
              <input
                type="text"
                placeholder='Endereço Cônjuge'
                {...register('EnderecoConjuge', { required: true })}
                onChange={(e) => setValue('EnderecoConjuge', e.target.value)}
              />
              {errors.EnderecoConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            {/* Row 4*/}

            <div
              className='box bairroPf'
            >
              <input
                type="text"
                placeholder='Bairro Cônjuge'
                {...register('BairroConjuge', { required: true })}
                onChange={(e) => setValue('BairroConjuge', e.target.value)}
              />
              {errors.BairroConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
              className='box estadoPf'
            >
              <input
                type="text"
                maxLength='2'
                title='2 dígitos'
                placeholder='Estado Cônjuge'
                {...register('EstadoConjuge', { required: true })}
                onChange={(e) => setValue('EstadoConjuge', maskRemoveCharactersNotNumeric(e.target.value))}
              />
              {errors.EstadoConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
              className='box cidadePf'
            >
              <input
                type="text"
                placeholder='Cidade Cônjuge'
                {...register('CidadeConjuge', { required: true })}
                onChange={(e) => setValue('CidadeConjuge', e.target.value)}
              />
              {errors.CidadeConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
              className='box complementoPf'
            >
              <input
                type="text"
                placeholder='Complemento Cônjuge'
                {...register('ComplementoConjuge', { required: true })}
                onChange={(e) => setValue('ComplementoConjuge', e.target.value)}
              />
              {errors.ComplementoConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
              className='box profissaoPf'
            >
              <input
                type="text"
                placeholder='Profissão Cônjuge'
                {...register('ProfissaoConjuge', { required: true })}
                onChange={(e) => setValue('ProfissaoConjuge', e.target.value)}
              />
              {errors.ProfissaoConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>

            <div
              className='box rendaPf'
            >
              <input
                type="text"
                placeholder='Renda Cônjuge'
                {...register('RendaConjuge', { required: true })}
                onChange={(e) => setValue('RendaConjuge', maskCurrency(e.target.value))}
              />
              {errors.RendaConjuge && <span className='erroForm'>&gt; Obrigatório</span>}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PfForm