/* eslint-disable */
import React from 'react'
import sebrae from '../assets/imgs/sebrae.png'

const BxSectionD = () => {
    return (
        <section id="sectionD">
            <div className="container">
                <header>
                    <img className="brand" src={sebrae} alt="Logo Sebrae" />
                    <h2 style={
                        {
                            color: '#FFFFFF',
                            fontWeight: 'normal',
                            fontSize: '2.125rem',
                            textAlign: 'right',
                        }
                    }>
                        Uma parceria com <br />
                        <strong
                            style={
                                {
                                    fontStyle: 'italic',
                                    fontSize: '3.5rem',
                                }
                            }
                        >
                            soluções para sua empresa
                        </strong>
                    </h2>
                </header>
                <div className="intro-sebrae">
                    <p>O Sebrae está oferecendo 10 capacitações online GRATUITAS aos interessados. Mesmo sem efetivação do crédito, você pode ter acesso a ferramentas que te ajudam a gerenciar melhor seus negócios.</p>
                    <span>Aproveite essa oportunidade!</span>
                </div>
                <address className="capacitacoes">
                    <div>
                        <h3>Empreendedorismo – 2h</h3>
                        <a
                            href="https://digital.sebraers.com.br/produtos/empreendedorismo/curso/zoom-no-cliente-como-criar-valor-no-mercado/"
                            target='_blank' rel='noopener noreferrer'
                        >
                            Zoom no cliente como criar valor no mercado
                        </a>
                    </div>
                    <div>
                        <h3>Estratégia – 6h</h3>
                        <a
                            href="https://digital.sebraers.com.br/produtos/estrategia/curso/planejamento-para-inovar/"
                            target='_blank' rel='noopener noreferrer'
                        >
                            Planejamento para inovar
                        </a>
                    </div>
                    <div>
                        <h3>Mercado – 3h</h3>
                        <a
                            href="https://digital.sebraers.com.br/produtos/mercado/curso/economia-colaborativa-a-tendencia-que-pode-transformar-seu-negocio/"
                            target='_blank' rel='noopener noreferrer'
                        >
                            Economia colaborativa a tendência que pode transformar em negócio
                        </a>
                    </div>
                    <div>
                        <h3>Processos – 4h</h3>
                        <a href="https://digital.sebraers.com.br/produtos/processos/curso/mapeando-o-fluxo-de-valor/"
                            target='_blank' rel='noopener noreferrer'
                        >
                            Mapeando o fluxo de valor
                        </a>
                    </div>
                    <div>
                        <h3>Finanças – 12h</h3>
                        <a
                            href="https://digital.sebraers.com.br/produtos/financas/curso/curso-online-panambi-garanti/"
                            target='_blank' rel='noopener noreferrer'
                        >
                            Curso on line Panambi Garanti
                        </a>
                        <a
                            href="https://digital.sebraers.com.br/produtos/financas/curso/financas-empresariais-controlar-para-expandir/"
                            target='_blank' rel='noopener noreferrer'
                        >
                            Finanças Empresariais Controlar para Expandir
                        </a>
                    </div>
                    <div>
                        <h3>Marketing e Vendas – 19h</h3>
                        <a
                            href="https://digital.sebraers.com.br/produtos/marketing-e-vendas/curso/potencialize-suas-vendas/"
                            target='_blank' rel='noopener noreferrer'
                        >
                            Potencialize suas vendas
                        </a>
                        <a
                            href="https://digital.sebraers.com.br/produtos/marketing-e-vendas/curso/marketing-digital-direto-ao-ponto/"
                            target='_blank' rel='noopener noreferrer'
                        >
                            Marketing Digital Direto ao Ponto
                        </a>
                    </div>
                    <div>
                        <h3>Pessoas (RH) – 3h</h3>
                        <a
                            href="https://digital.sebraers.com.br/produtos/pessoas/curso/recrutamento-e-selecao-como-formar-um-time-vencedor/"
                            target='_blank' rel='noopener noreferrer'
                        >
                            Recrutamento e seleção como formar um time vencedor
                        </a>
                    </div>
                    <div>
                        <h3>Inovação – 3h</h3>
                        <a
                            href="https://digital.sebraers.com.br/produtos/pessoas/curso/neurolideranca-nas-organizacoes/"
                            target='_blank' rel='noopener noreferrer'
                        >
                            Neuroliderança nas organizações
                        </a>
                    </div>
                </address>
            </div>
        </section>
    )
}

export default BxSectionD