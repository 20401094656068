// /* eslint-disable no-unused-expressions */
import React from 'react'
import { maskCurrency, maskRemoveLetters } from '../utils/masks'

const CreditoForm = ({ register, errors, setValue, watch }) => {

  const nParcelas = watch('NumeroDeParcelas')
  const parcelas = nParcelas === '34x' ? '02 meses de carência' : 0
  const carencia = watch('Carencia')

  // Limpar campo carência
  const clearPaymentArrearsField = () => {
    if (carencia)
      setValue('Carencia', '')
    else
      return false
  }

  return (
    <div className="container creditoForm grid">
      <div
        className='box valorDeCreditoPretendidoCf'
      >
        <input
          type="text"
          placeholder='Valor de Crédito Pretendido'
          {...register('ValorCreditoPretendido', { required: true })}
          onChange={(e) => setValue('ValorCreditoPretendido', maskCurrency(e.target.value))}
        />
        {errors.ValorCreditoPretendido && <span className="erroForm">&gt; Obrigatório</span>}
      </div>

      <div
        className='box numeroDeParcelasCf'
      >
        <select
          {...register('NumeroDeParcelas', { required: true })}
          onChange={(e) => {
            clearPaymentArrearsField()
            setValue('NumeroDeParcelas', e.target.value)
          }}
        >
          <option value="" disabled selected>Número de Parcelas</option>
          <option value="34x">34x</option>
          <option value="36x">36x</option>
        </select>
        {errors.NumeroDeParcelas && <span className='erroForm'>&gt; Obrigatório</span>}
      </div>

      <div
        className='box carenciaCf'
      >
        <select
          {...register('Carencia', { required: true })}
          onChange={(e) => setValue('Carencia', e.target.value)}
        >
          <option value="" disabled selected>Carência</option>
          <option value={parcelas}>{parcelas}</option>
        </select>
        {errors.Carencia && <span className='erroForm'>&gt; Obrigatório</span>}
      </div>

      <div className='finalidadeDoCreditoCf bgColorElement'>
        <label>
          <span className='label'>Qual é a finalidade do crédito?</span>
        </label>
        <div>
          <label>
            <span className='label'>Capital de Giro (compra de estoque, pagamento de fornecedores, etc.)</span>
            <input
              className='boxCapitalDeGiroCf'
              type="radio"
              name='finalidadeDoCredito'
              value='0'
              defaultChecked={true}
              {...register('FinalidadeDoCredito')}
              onChange={(e) => setValue('FinalidadeDoCredito', e.target.value)}
            />
          </label>
          <label>
            <span className='label'>Pagamento 13º salário</span>
            <input
              className='boxPagamento13SalarioCf'
              type="radio"
              name='finalidadeDoCredito'
              value='15'
              {...register('FinalidadeDoCredito')}
              onChange={(e) => setValue('FinalidadeDoCredito', e.target.value)}
            />
          </label>
          <label>
            <span className='label'>Investimento (veículos, máquinas, equipamentos, ampliações, reformas, etc.)</span>
            <input
              className='boxInvestimentoCf'
              type="radio"
              name='finalidadeDoCredito'
              value='1'
              {...register('FinalidadeDoCredito')}
              onChange={(e) => setValue('FinalidadeDoCredito', e.target.value)}
            />
          </label>
          <label>
            <span className='label'>Marketing</span>
            <input
              className='boxMarketingCf'
              type="radio"
              name='finalidadeDoCredito'
              value='16'
              {...register('FinalidadeDoCredito')}
              onChange={(e) => setValue('FinalidadeDoCredito', e.target.value)}
            />
          </label>
          <label>
            <span className='label'>Tecnologia (computadores, sistemas, etc.)</span>
            <input
              className='boxTecnologiaCf'
              type="radio"
              name='finalidadeDoCredito'
              value='17'
              {...register('FinalidadeDoCredito')}
              onChange={(e) => setValue('FinalidadeDoCredito', e.target.value)}
            />
          </label>
        </div>
      </div>

      <div className="empregados">
        <div
          className='box numeroAtualFuncionarioCf'
        >
          <input
            type="text"
            maxLength={'4'}
            title='Somente números são permitidos'
            placeholder='Quantos empregados a empresa possui?'
            {...register('NumeroAtualFuncionario', { required: true })}
            onChange={(e) => setValue('NumeroAtualFuncionario', maskRemoveLetters(e.target.value))}
          />
          {errors.NumeroAtualFuncionario && <span className="erroForm">&gt; Obrigatório</span>}
        </div>

        <div
          className='box provaveisEmpregosCf'
        >
          <input
            type="text"
            maxLength={'4'}
            title='Somente números são permitidos'
            placeholder='Serão gerados novos empregos? Quantos?'
            {...register('ProvaveisEmpregos', { required: true })}
            onChange={(e) => setValue('ProvaveisEmpregos', maskRemoveLetters(e.target.value))}
          />
          {errors.ProvaveisEmpregos && <span className="erroForm">&gt; Obrigatório</span>}
        </div>
      </div>
    </div>
  )
}

export default CreditoForm