import React from 'react'
import panambiCover from '../assets/imgs/panambi-cover.jpg'

const Header = () => {
  return (
    <header>
        <img id="banner" src={panambiCover} alt="Banner Panambi Garanti" />
    </header>
  )
}

export default Header